<template>
    <div>
        <b-card class="mb-3 mt-3">
            <b-container fluid class="ml-3 mt-2">
                <b-row align-v="center" class="my-4">
                    <b-form-file v-model="file" :state="Boolean(file)" placeholder="Escolha um arquivo ou solte-o aqui..."
                        drop-placeholder="Solte o arquivo aqui..." class="ff"></b-form-file>
                </b-row>

                <b-row v-if="mes">
                    <b-col md="3">
                        <b-form-group label="Mês de Referência" label-for="filtroMesRef" class="form">
                            <b-form-select id="filtroMesRef" v-model="filtroMesRef" :options="meses"
                                @change="atualizarDataRef" />
                        </b-form-group>
                    </b-col>

                    <b-col md="3">
                        <b-form-group label="Ano de Referência" label-for="filtroAnoRef" class="form">
                            <b-form-input id="filtroAnoRef" type="number" v-model="filtroAnoRef"
                                @change="atualizarDataRef" />
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row align-v="center" class="my-4">
                    <b-button @click="handleFileUpload" class="ml-2">Upload</b-button>
                </b-row>
            </b-container>
        </b-card>

    </div>
</template>

<script>
import meses from '@/utils/meses';

export default {
    data() {
        return {
            file: null,
            meses: meses(),
            mesRef: '',
            filtroMesRef: new Date().getMonth() + 1,
            mesRefInput: '',
            filtroAnoRef: new Date().getFullYear()
        }
    },
    props: {
        mes: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        handleFileUpload(event) {

            this.$emit('file-uploaded', { file: this.file, mesRef: this.mesRefInput });
        },
        atualizarDataRef() {
            if (this.filtroMesRef && this.filtroAnoRef) {
                const mesIndexZeroBase = this.filtroMesRef - 1;
                this.mesRefInput = `${this.filtroAnoRef}-${String(
                    mesIndexZeroBase + 1
                ).padStart(2, '0')}-01`;
            }
        }
    },
    created() {
        this.atualizarDataRef();
    }
}
</script>

<style scoped>
.ff{
    width: 97%;
}
</style>