<template>
    <div>
        <b-spinner v-if="loading" text-align="center" variant="success" label="Spinning" class="ml-2 mt-3" />

        <b-card class="mb-3 mt-3">
            <b-container fluid class="ml-3 mt-2">
                <b-row align-v="center" class="my-4">
                    <b-button @click="downloadContatos" class="ml-2">Download</b-button>
                </b-row>
            </b-container>
        </b-card>
        
        <FormFile @file-uploaded="uploadContatos" :mes="false" v-if="hasPermission('backoffice_edit')"></FormFile>
    </div>
</template>

<script>
import FormFile from '@/components/form-file';
import { downloadContatos, uploadContatos } from './NetEnergetico.service';
import { hasPermission } from '@/features/auth/auth.acl';

export default {
    data() {
        return {
            loading: false,
        }
    },
    components: {
        FormFile
    },
    methods: {
        uploadContatos(data) {
            this.loading = true;

            uploadContatos(data)
                .then((response) => {
                })
                .catch((error) => {
                    console.error('Erro ao fazer upload:', error);
                }).finally(() => {
                    this.loading = false;
                });
        },
        downloadContatos(net) {

            this.loading = true;

            downloadContatos(net)
                .then((response) => {

                })
                .catch((error) => {
                    console.error('Erro ao buscar dados:', error);
                }).finally(() => {
                    this.loading = false;
                });
        },
        hasPermission(requiredAcl) {
            return hasPermission(requiredAcl);
        }
    }
}
</script>