<template>
    <b-form-select class="select" :options="opcoes" v-model="contrato" size="sm" @change="change" />
</template>

<script>
export default {
    data() {
        return {
            contrato: {}
        }
    },
    computed: {
        opcoes() {
            // Mapeando para que o texto exibido seja o cliqccee e o valor seja o objeto inteiro
            return this.net.possiveisNets.map(c => ({
                value: c, 
                text: `${c.cliqccee} - ${c.vigenciaIniMontante}/${c.vigenciaFimMontante}`
            }));
        }
    },
    methods: {
        change() {
            this.$emit('cliqSelecionado', this.net, this.contrato);
        }
    },
    props: {
        net: {
            type: Object,
            default: null
        }
    }
}
</script>

<style scoped>
.select{
    width: 70px;
}
</style>