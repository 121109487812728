<template>
    <div v-if="dados && !loading" id="tab">
        <b-table bordered small :fields="fields" :items="dados" class="nettable">

            <template #thead-top="data">
                <b-tr>
                    <b-th colspan="1">
                        <b-form-checkbox v-model="selectAll" @change="toggleSelectAll"></b-form-checkbox>
                    </b-th>
                    <b-th colspan="16" variant="primary"><b-p class="d-flex justify-content-center">Net
                            Energético</b-p></b-th>
                </b-tr>
            </template>

            <template #cell(selected)="data">
                <div>
                    <b-form-checkbox v-model="data.item.selected"
                        @change="onCheckboxChange(data.item)"></b-form-checkbox>
                </div>
            </template>

            <template #cell(ajusteCliq)="data">
                <ContratoCceeSelect :net="data.item"
                @cliqSelecionado="atualizaNet"></ContratoCceeSelect>
            </template>

            <template #cell(vigIni)="data">
                <p class="data">{{ data.value }}</p>
            </template>
            <template #cell(vigFim)="data">
                <p class="data">{{ data.value }}</p>
            </template>

            <template #cell(volumeccee)="data">
                <p>{{ formatNumber(data.value, 3) }}</p>
            </template>

            <template #cell(valorTotalRegistro)="data">
                <p>{{ formatNumber(data.value, 3) }}</p>
            </template>

            <template #cell(resultEnergetico)="data">
                <p>{{ formatNumber(data.value, 3) }}</p>
            </template>

            <template #cell(resultFinanceiro)="data">
                <p>{{ formatNumber(data.value, 3) }}</p>
            </template>

            <template #cell(enviado)="data">
                <IconValid :value="data.item.enviado"></IconValid>
            </template>
            <template #cell(aceito)="data">
                <IconValid :value="data.item.aceito"></IconValid>
            </template>

            <template #cell(boletas)="row">
                <b-icon icon="chevron-bar-expand" scale="1" @click="row.toggleDetails" class="icon"></b-icon>
            </template>

            <template #cell(xml)="row" >
                <div class="d-flex justify-content-center align-items-center">
                    <b-icon icon="file-earmark-arrow-down" scale="2" @click="downloadXml(row.item)" class="icon"></b-icon>
                </div>
            </template>

            <template #cell(email)="row">
                <div class="d-flex justify-content-center align-items-center">
                    <b-icon icon="envelope" scale="2" @click="enviaEmail(row.item)" class="icon"></b-icon>
                </div> 
            </template>

            <template #cell(ve)="row">
                <div class="d-flex justify-content-center align-items-center">
                    <b-icon icon="eye" scale="2" @click="visualizarEmail(row.item)" class="icon"></b-icon>
                </div> 
            </template>

            <template #row-details="row">
                <b-card>
                    <BoletaNetTable :dados="row.item"></BoletaNetTable>
                </b-card>
            </template>
        </b-table>
    </div>
</template>

<script>
import BoletaNetTable from './BoletaNetTable.vue';
import ContratoCceeSelect from './ContratoCceeSelect';
import IconValid from '@/components/iconValid';
import { enviaEmail } from './NetEnergetico.service';

export default {
    data() {
        return {
            fields: [{ key: 'selected', label: '' },
            { key: 'cliqccee', label: 'cliqCcee' },
            { key: 'ajusteCliq', label: 'Ajuste Cliq' },
            { key: 'vendedor', label: 'Vendedor' },
            { key: 'comprador', label: 'Comprador' },
            { key: 'statusRegistro', label: 'Status', headerTitle: 'Status Registro' },
            { key: 'vigIni', label: 'Início', headerTitle: 'Vigência Início' },
            { key: 'vigFim', label: 'Fim', headerTitle: 'Vigência Fim' },
            { key: 'volumeccee', label: 'Volume', headerTitle: 'Volume Contrato CCEE' },
            { key: 'resultEnergetico', label: 'Res Energético', headerTitle: 'Resultado Energético' },
            { key: 'resultFinanceiro', label: 'Res Financeiro', headerTitle: 'Resultado Financeiro' },
            { key: 'enviado', label: 'Enviado', sortable: true},
            { key: 'aceito', label: 'Aceito', sortable: true},
            { key: 'xml', label: 'XML'},
            { key: 've', label: 'V E', headerTitle: 'Visualizar Email'},
            { key: 'email', label: 'Email', headerTitle: 'Enviar Email' },
            { key: 'boletas', label: 'V B', headerTitle: 'Visualizar Boletas' }],
            selectAll: false,
        }
    },
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        dados: {
            type: Array,
            default: null
        }
    },
    components: {
        BoletaNetTable, ContratoCceeSelect, IconValid
    },
    methods: {
        onRowSelected(items) {
            //console.log('Selected rows:', items);
            this.$emit('rowSelected', { items: items });
        },
        downloadXml(net) {
            //console.log('net:', net);
            this.$emit('downloadXml', { net: net });
        },
        enviaEmail(net) {
            //console.log('net:', net);
            this.$emit('enviaEmail', { net: net });
        },
        visualizarEmail(net) {
            //console.log('net:', net);
            this.$emit('visualizarEmail', { net: net });
        },
        toggleSelectAll() {
            if (this.selectAll) {
                this.dados.forEach((objeto) => {
                    this.$set(objeto, 'selected', true);
                });
            } else {
                this.dados.forEach((objeto) => {
                    this.$set(objeto, 'selected', false);
                });
            }

            this.onRowSelected(this.dados.filter((item) => item.selected));
        },
        onCheckboxChange(item) {
            this.onRowSelected(this.dados.filter((item) => item.selected));
        },
        formatNumber(number, decimals) {
            // Verifica se o número é zero
            if (number == 0) {
                return 0;
            } else {
                // Caso contrário, formata o número com o número especificado de casas decimais
                return number.toLocaleString('pt-BR', { minimumFractionDigits: decimals });
            }
        },
        atualizaNet(net, contrato) {
            this.$emit('atualizaNet', net, contrato);
        },
    }
};
</script>

<style scoped>
*{
    margin: 0;
}

.data{
    width: 90px;
}

.nettable {
  font-size: 12px;
}

.icon{
    height: 20px;
}
</style>